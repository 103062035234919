<template>
  <select v-model="bank" :class="className">
    <option selected :value="''" disabled>{{ $t('front.common.bankSelect') }}</option>
    <template v-for="item in bankList" v-bind:key="item.bankCode">
      <option :value="item.bankCode" >{{ item.bankNameKr }}</option>
    </template>
  </select>
</template>

<script>
import { getBankList } from '@/api/member'

export default {
  name: 'BankList',
  props: [
    'className'
  ],
  data () {
    return {
      bank: '',
      bankList: []
    }
  },
  watch: {
    bank () {
      if (this.bank) {
        this.$emit('onChange', this.bank)
      }
    }
  },
  created () {
    this.loadBankList()
  },
  methods: {
    loadBankList () {
      getBankList({}).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          const data = result.data
          this.bankList = data.list
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
